<template>
  <v-row>
    <v-col class="pt-0">
      <v-select
        class="mt-0"
        label="Month"
        :items="months"
        v-model="selectedMonth"
        :rules="rules"
        menu-props="auto"
      ></v-select>
    </v-col>
    <v-col class="pt-0">
      <v-select
        class="mt-0"
        label="Year"
        menu-props="auto"
        :items="years"
        v-model="selectedYear"
        :rules="rules"
      ></v-select>
    </v-col>
  </v-row>
</template>

<script>
export default {
  props: ['value', 'rules'],
  data: () => ({
    months: [],
    years: []
  }),
  computed: {
    selectedMonth: {
      get() {
        const month = this.value.substring(0, 2);
        return month !== '' ? month : '';
      },
      set(val) {
        this.$emit('input', `${val}${this.formattedYear(this.selectedYear)}`);
      }
    },
    selectedYear: {
      get() {
        const currentYear = new Date().getFullYear().toString();
        const year = currentYear.substring(0, 2) + this.value.substring(2);
        const yearIndex = this.years.findIndex((y) => y === year);

        return this.value === '' || yearIndex < 0 ? '' : this.years[yearIndex];
      },
      set(val) {
        this.$emit('input', `${this.selectedMonth}${this.formattedYear(val)}`);
      }
    }
  },
  methods: {
    formattedYear(year) {
      return year.substring(2);
    }
  },
  mounted() {
    for (let i = 1; i < 13; i++) {
      if (i >= 10) {
        this.months.push(`${i}`);
      } else {
        this.months.push(`0${i}`);
      }
    }

    const currentYear = new Date().getFullYear();
    for (let i = currentYear; i < currentYear + 20; i++) {
      this.years.push(`${i}`);
    }
  }
};
</script>
